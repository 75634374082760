.admin {
    height: 100vh;
    background: var(--extraLightGray);
    display: flex;
    overflow: hidden;
}

.admin .adminDiv {
    width: 82%;
    padding: 40px 30px;
    overflow-y: auto;
    height: 100vh;
}

.admin .adminDiv .primaryBtn,
.admin .adminDiv .secondaryBtn,
.admin .adminDiv .dangerBtn,
.admin .adminDiv .primaryBtn2 {
    font-size: 0.85rem;
}

.admin .adminDiv .tableDiv {
    padding: 0;
}

.admin .adminDiv .tableDiv .tableContent {
    box-shadow: none;
}



.admin .adminDiv .tableDiv .id,
.admin .adminDiv .tableDiv .idField {
    width: 150% !important;
}

.admin .adminDiv .tableDiv .rows .row .titleField .title {
    width: 100%;
}

.admin .adminDiv .tableDiv .rows .row .icon {
    width: 15px !important;
    height: 15px !important;
}

.admin .adminDiv .tableDiv .rows .row .arrowRight {
    width: 20px !important;
    height: 20px !important;
}

.admin .adminDiv .overviewBox {
    border-radius: 8px;
    background: var(--white);
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.admin .adminDiv .overviewBox .secondaryHeading {
    font-size: 1rem;
}

.admin .adminDiv .overviewBox .value {
    font-size: 1.5rem;
    font-weight: 600;
}


/* ****************************** LOGIN PAGE ****************************** */

.adminLoginDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.adminLoginDiv .adminLoginContent {
    border-radius: 8px;
    height: fit-content;
    min-width: 50%;
    padding: 30px 50px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.adminLoginDiv .adminLoginContent .primaryHeading,
.adminLoginDiv .adminLoginContent .primaryBtn {
    width: 100%;
    text-align: center;
}




/* ****************************** HEADER PAGE ****************************** */

.adminHeaderDiv {
    position: sticky;
    background: var(--lightGray);
    height: 100vh;
    top: 0;
    left: 0;
    width: 18%;
    padding: 30px 20px;
    overflow-y: auto;
    scrollbar-width: none;
}

.adminHeaderDiv::-webkit-scrollbar {
    display: none;
}

.adminHeaderDiv .horizontalLine {
    background: var(--gray);
}

.adminHeaderDiv .adminHeaderContent {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.adminHeaderDiv .adminHeaderContent .faithzyLogoDiv {
    margin: auto;
    width: 90px;
    height: 90px;
}

.adminHeaderDiv .adminHeaderContent .mainMenu {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.adminHeaderDiv .adminHeaderContent .mainMenu li a {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
}

.adminHeaderDiv .adminHeaderContent .mainMenu li a.activeLi {
    color: var(--black);
    font-weight: 600;
}

.adminHeaderDiv .adminHeaderContent .mainMenu li a.activeLi::before {
    position: absolute;
    top: 10%;
    left: -5%;
    background: var(--black);
    width: 2%;
    height: 80%;
    content: "";
    border-radius: 8px;
}

.adminHeaderDiv .adminHeaderContent .mainMenu .adminChatHeader {
    position: relative;
}

.adminHeaderDiv .adminHeaderContent .mainMenu .adminChatHeader .unreadCountBadge {
    top: 10%;
    right: 0%;
}




/* ****************************** EMPLOYEES PAGE ****************************** */

.adminEmployeesDiv .addNewModelDiv .popupContent {
    min-width: 60%;
}

.adminEmployeesDiv .dropdownPlus {
    width: 100%;
    margin: 5px 0px;
}

.adminEmployeesDiv .editEditorAccess {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 0px;
}

.adminEmployeesDiv .editEditorAccess .accessBtn {
    display: flex;
    justify-content: space-between;
    margin: 0px 15px;
}

.adminEmployeesDiv .editEditorAccess .accessBtn .dropdownPlus {
    width: fit-content;
    margin: 0;
}

.adminEmployeesDiv .showDetailsModelDiv .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.adminEmployeesDiv .showDetailsModelDiv .accessTo {
    margin-top: 5px;
    font-size: 0.9rem;
}

.adminEmployeesDiv .changePassDiv {
    width: 100%;
    margin: 10px 0px;
    user-select: none;
}

.adminEmployeesDiv .changePassDiv .upper {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    padding: 10px;
    cursor: pointer;
    background: transparent;
    transition: 0.1s;
}

.adminEmployeesDiv .changePassDiv .upper:hover {
    background: var(--extraLightGray);
}

.adminEmployeesDiv .changePassDiv .upper .icon {
    width: 25px;
    height: 25px;
}

.adminEmployeesDiv .changePassDiv .lower {
    margin-top: 20px;
}

.adminEmployeesDiv .changePassDiv .lower .secondaryBtn {
    margin-top: 10px;
}




/* ****************************** ADMIN TERMS & CONDITIONS PAGE ****************************** */

.adminTermsDiv .adminTermsContent {
    width: 100%;
    background: var(--white);
    padding: 20px 30px;
    margin-bottom: 20px;
    min-height: 85vh;
    border: 1px solid var(--gray);
    outline: none;
    border-radius: 8px;
}

.adminTermsDiv .form {
    height: 100%;
    width: 100%;
}

.adminTermsDiv .form .reactQuill {
    height: 55vh;
    width: 100%;
    margin-bottom: 50px;
}




/* ****************************** ADMIN SOCIAL LINKS PAGE ****************************** */

.socialLinksDiv .horizontalLine {
    margin-top: 10px;
}

.socialLinksDiv .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.socialLinksDiv .socialCheckInput {
    cursor: pointer;
}

.socialLinksDiv .row .platformName {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--lightBlackPrimary);
}

.socialLinksDiv .row .socialInput {
    width: 85%;
    padding: 8px 20px;
    outline: none;
    border: 1px solid var(--gray);
    border-radius: 8px;
}




/* ****************************** ADMIN CATEGORIES PAGE ****************************** */

.adminCategoriesDiv .popupDiv .dropdownPlus {
    margin: 5px 0px;
    width: 100%;
}





/* ****************************** ADMIN COUPONS PAGE ****************************** */

.adminCouponsDiv .detailsModelDiv .details .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.adminCouponsDiv .detailsModelDiv .buttonsDiv {
    margin-top: 20px;
}





/* ****************************** ADMIN FEE MANAGEMENT PAGE ****************************** */

.adminFeeDiv .adminFeeContent {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-top: 20px;
}

.adminFeeDiv .adminFeeContent .left {
    min-width: 40%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.adminFeeDiv .adminFeeContent .box {
    width: 100%;
    background: var(--white);
    border-radius: 8px;
    height: fit-content;
}

.adminFeeDiv .adminFeeContent .box .secondaryHeading {
    color: var(--white);
}

.adminFeeDiv .adminFeeContent .box .secondaryHeading span {
    color: var(--secondaryCopper);
    ;
}

.adminFeeDiv .adminFeeContent .box .secondaryBtn {
    margin-top: 10px;
}

.adminFeeDiv .adminFeeContent .box .upper {
    padding: 10px 18px;
    background: var(--primaryBlue);
    color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.adminFeeDiv .adminFeeContent .box .lower {
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.adminFeeDiv .adminFeeContent .box .lower .buttonsDiv {
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: right;
}

.adminFeeDiv .adminFeeContent .right .lower .discountType {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.adminFeeDiv .adminFeeContent .right .lower .discountType .radioBtn {
    display: flex;
    gap: 5px;
}





/* ****************************** ADMIN ORDERS MANAGEMENT PAGE ****************************** */

.adminOrdersDiv .tableDiv .header .title,
.adminOrdersDiv .tableDiv .rows .row .titleField {
    width: 220%;
}

.adminOrdersDiv .tableDiv .rows .row .titleField .title {
    width: 100% !important;
}

.adminOrdersDiv .popupDiv .rows {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.adminOrdersDiv .popupDiv .rows .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.adminOrdersDiv .popupDiv .contactBuyerBtnDiv {
    display: flex;
    justify-content: right;
    width: 100%;
}







/* ****************************** ADMIN CHATS PAGE ****************************** */

.admin .adminDiv .chatDiv {
    margin: 0;
}





/* ****************************** ADMIN DASHBOARD PAGE ****************************** */

.adminDashboardDiv .dashboardOverview {
    display: flex;
    gap: 20px;
}

.adminDashboardDiv .dashboardOverview .left,
.adminDashboardDiv .dashboardOverview .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.adminDashboardDiv .dashboardOverview .left .overviewBoxA .row,
.adminDashboardDiv .dashboardOverview .right .overviewBoxA .row {
    display: flex;
    justify-content: space-between;
}

.adminDashboardDiv .dashboardOverview .left .leftBottom,
.adminDashboardDiv .dashboardOverview .right .rightTop {
    display: flex;
    gap: 20px;
}

.adminDashboardDiv .adminOrdersDiv {
    margin: 40px 0px;
}






/* ****************************** ADMIN REVENUE PAGE ****************************** */

.adminRevenueDiv .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.adminRevenueDiv .header .headerRight {
    display: flex;
    gap: 20px;
    align-items: center;
}

.adminRevenueDiv .header .headerRight .customDateRange label {
    margin-left: 15px;
}

.adminRevenueDiv .header .headerRight .customDateRange input {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid var(--gray);
    outline: none;
    margin-left: 8px;
}

.adminRevenueDiv .revenueOverviewDetails {
    display: flex;
    gap: 20px;
}

.adminRevenueDiv .revenueDetailsUpper {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.adminRevenueDiv .revenueDetailsUpper .revenueBarChart {
    padding: 20px 25px;
    background: var(--white);
    border-radius: 8px;
    width: 75%;
}

.adminRevenueDiv .revenueDetailsUpper .revenueBarChart .upper {
    margin-top: 10px;
    margin-bottom: 20px;
}

.adminRevenueDiv .revenueDetailsUpper .revenueBarChartOverview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 24%;
}

.adminRevenueDiv .revenueDetailsUpper .revenueBarChartOverview .revenuePieChart .secondaryHeading {
    margin-bottom: 10px;
}


.adminRevenueDiv .revenueDetailsLower {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
}

.adminRevenueDiv .revenueDetailsLower .upper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.adminRevenueDiv .revenueDetailsLower .revenueLineChart,
.adminRevenueDiv .revenueDetailsLower .profitLineChart {
    width: 49%;
    background: var(--white);
    border-radius: 8px;
    padding: 15px 25px;
}




/* ****************************** ADMIN PAYMENTS PAGE ****************************** */

.adminPaymentsDiv .adminPaymentMethodsDiv {
    background: var(--white);
    padding: 20px 30px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.adminPaymentsDiv .paymentMethods {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--lightGray);
    padding: 13px 20px;
    border-radius: 8px;
    margin: 10px 0px;
}

.adminPaymentsDiv .paymentMethods .method {
    display: flex;
    align-items: center;
    gap: 10px;
}

.adminPaymentsDiv .paymentsDetails {
    background: var(--white);
    padding: 20px 30px;
    border-radius: 8px;
}

.adminPaymentsDiv .paymentsDetails .css-19kzrtu {
    padding: 0 !important;
}

.adminPaymentsDiv .pendingPaymentsDiv .popupDiv .sellerDetails .sellerHistory {
    width: 100%;
}

.adminPaymentsDiv .popupDiv .rowsParent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.adminPaymentsDiv .popupDiv .rowsParent .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.adminPaymentsDiv .popupDiv .rowsParent .row div {
    max-width: 75%;
    text-align: justify;
}

.adminPaymentsDiv .releasePopupContent {
    max-width: 50% !important;
}




/* ****************************** ADMIN SEND EMAIL PAGE ****************************** */

.adminSendEmailPopupDiv .buttonsSection .upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
}

.adminSendEmailPopupDiv .secondaryHeading .countText {
    font-size: 1rem;
    color: var(--darkGray);
    margin-left: 5px;
}

.adminSendEmailPopupDiv .buttonsSection .buttonInputDiv {
    display: flex;
    gap: 10px;
}

.adminSendEmailPopupDiv .buttonsSection .buttonInputDiv .btnUrlInput {
    min-width: 65%;
}

.adminSendEmailPopupDiv .buttonsSection .buttonInputDiv .removeBtnDiv {
    min-width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminSendEmailPopupDiv .buttonsSection .buttonInputDiv .removeBtnDiv .removeBtn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: var(--darkGray);
    transition: 0.2s;
}

.adminSendEmailPopupDiv .buttonsSection .buttonInputDiv .removeBtnDiv .removeBtn:hover {
    color: var(--black);
}

.adminSendEmailPopupDiv .recipientTypeDiv{
    width: 100%;
    position: relative;
}
.adminSendEmailPopupDiv .recipientTypeDiv .recipientInput{
    flex-grow: 1;
    padding-right: 40px;
}
.adminSendEmailPopupDiv .recipientTypeDiv .emailAddButton{
    bottom: 17px;
    right: 20px;
}

.adminSendEmailPopupDiv h3{
    font-size: 1rem;
    margin-bottom: 5px;
}
.adminSendEmailPopupDiv .recipientPreview{
    width: 100%;
}
.adminSendEmailPopupDiv .recipientPreview .recipientHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.adminSendEmailPopupDiv .recipientPreview .recipientHeader .moveButtons span{
    font-size: 0.8rem;
    cursor: pointer;
    color: var(--lightBlackPrimary);
    transition: 0.1s;
}
.adminSendEmailPopupDiv .recipientPreview .recipientHeader .moveButtons span:hover{
    color: var(--black);
}




/* ****************************** ADMIN DISPUTES + ADMIN MANAGE DISPUTE ****************************** */

.adminManageDisputeDiv .adminManageDisputeContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--white);
    padding: 20px 30px;
    border-radius: 8px;
}

.adminManageDisputeDiv .adminManageDisputeContainer {
    display: flex;
    justify-content: space-between;
}

.adminManageDisputeDiv .adminManageDisputeContainer .chatRoom {
    width: 58%;
}

.adminManageDisputeDiv .adminManageDisputeContainer .adminDisputeDetails {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 20px;
    overflow-y: auto;
}

.adminManageDisputeDiv .adminManageDisputeContainer .adminDisputeDetails .item {
    display: flex;
    gap: 15px;
}

.adminManageDisputeDiv .adminManageDisputeContainer .adminDisputeDetails .item img {
    width: 80px;
}

.adminManageDisputeDiv .adminManageDisputeContainer .adminDisputeDetails .item p {
    font-size: 0.85rem;
    color: var(--lightBlackPrimary);
}

.adminManageDisputeDiv .adminManageDisputeContainer .adminDisputeDetails .rows {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
}

.adminManageDisputeDiv .adminManageDisputeContainer .adminDisputeDetails .rows .row {
    display: flex;
    justify-content: space-between;
}






/* ********************** ADMIN FAQ ********************** */


.adminFAQContent {
    background: var(--white);
    border-radius: 8px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.adminFAQContent .form .buttonsRow {
    display: flex;
    gap: 10px;
}

.faqList {
    margin-top: 10px;
}

.faqRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0;
    gap: 20px;
}

.faqRow .faqQA {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.faqRow .faqQA .question {
    font-size: 1rem;
    font-weight: 500;
    color: var(--lightBlackPrimary);
}

.faqRow .faqQA .answer {
    font-size: 0.95rem;
    color: var(--lightBlackPrimary);
}

.faqRow .faqActions {
    display: flex;
    gap: 10px;
    align-items: center;
}

.faqRow .faqActions button {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1.1rem;
    padding: 6px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    color: var(--lightBlackPrimary);
}

.faqRow .faqActions .editBtn:hover {
    background-color: #e7f3ff;
    color: #007bff;
}

.faqRow .faqActions .deleteBtn:hover {
    background-color: #ffe8e8;
    color: #dc3545;
}








/* ********************** Email Templates Section Styles ********************** */

.templatesSection {
    padding: 10px 30px;
    margin-bottom: 20px;
}

.templatesSection .templatesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px 0px;
}

.templatesSection .templatesGrid .templateCard {
    background: var(--white);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.templatesSection .templatesGrid .templateCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.templatesSection .templatesGrid .templateCard .templateImageContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 20px;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer:hover .templateOverlay {
    opacity: 1;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateName {
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateDescription {
    color: var(--white);
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 20px;
    opacity: 0.9;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .templateBtn {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .previewBtn {
    background-color: var(--white);
    color: var(--primaryBlue);
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .previewBtn:hover {
    background-color: var(--gray);
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .downloadBtn {
    background-color: var(--primaryCopper);
    color: var(--white);
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .downloadBtn:hover {
    background-color: var(--primaryCopperDark);
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .copyBtn {
    background-color: var(--primaryBlue);
    color: var(--white);
}

.templatesSection .templatesGrid .templateCard .templateImageContainer .templateOverlay .templateActions .copyBtn:hover {
    background-color: var(--black);
}



/* Modal Styles */
.templatesSection .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.templatesSection .modalOverlay .modalContent {
    background: var(--white);
    border-radius: 8px;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
}

.templatesSection .modalOverlay .modalContent .modalHeader {
    padding: 15px 20px;
    border-bottom: 1px solid var(--gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.templatesSection .modalOverlay .modalContent .modalHeader .modalCloseBtn {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--darkGray);
    cursor: pointer;
    padding: 5px;
    transition: color 0.3s ease;
}

.templatesSection .modalOverlay .modalContent .modalHeader .modalCloseBtn:hover {
    color: var(--primaryBlue);
}

.templatesSection .modalOverlay .modalContent .modalBody {
    padding: 20px;
}

.templatesSection .modalOverlay .modalContent .modalHeader .modalActions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.templatesSection .modalOverlay .modalContent .modalHeader .modalActions .templateBtn {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    background-color: var(--secondaryBlue);
    color: var(--white);
}

.templatesSection .modalOverlay .modalContent .modalHeader .modalActions .templateBtn:hover {
    background-color: var(--black);
}







/* ********************** Admin Emails ********************** */

.adminEmailsDiv .pendingEmailsPreview {
    margin-top: 15px;
}

.adminEmailsDiv .emailChipContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
}

.adminEmailsDiv .emailChip {
    background-color: var(--orangeBgLight);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 13px;
}

.adminEmailsDiv .emailChip button {
    background: none;
    border: none;
    margin-left: 8px;
    cursor: pointer;
    color: var(--primaryCopperDark);
    display: flex;
    align-items: center;
}


.adminEmailsDiv .emailInputContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    position: relative;
}

.adminEmailsDiv .emailInput {
    flex-grow: 1;
    padding-right: 40px;
}

.adminEmailsDiv .emailAddButton {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    color: var(--primaryCopperDark);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminEmailsDiv .emailAddButton:disabled {
    color: var(--darkGray);
    cursor: not-allowed;
}

.adminEmailsDiv .adminEmailsPopupContent{
    width: 50% !important;
}