@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    list-style: none;
    text-decoration: none;
}

:root {
    --primaryCopper: hsla(21, 100%, 55%, 0.89);
    --primaryCopperDark: rgb(234, 82, 0);
    --secondaryCopper: var(--primaryCopper);
    --primaryBlue: #212121;
    --secondaryBlue: var(--primaryBlue);

    --orangeBgLight: #FFF2EC;
    --orangeBg: #ffdccb;
    --orangeBgHover: #ffd3be;
    --orangeOnDarkBg: hsl(21, 100%, 65%);

    --gray: #D9D9D9;
    --darkGray: #80818D;
    --lightGray: #F0F0F0;
    --extraLightGray: #f6f6f6;
    --extraLightGrayPlus: #fafafa;
    --white: white;
    --black: black;
    --lightBlack: rgba(0, 0, 0, 0.7);
    --lightBlackPrimary: #3D3D3D;
    --success: #18AE00;
    --danger: #FF3838;
    --warning: #DE8500;
    --star: #ffc107;
    --primaryBg: white;
}



/* OLD THEME  */
/* :root {
    --primaryCopper: #8A4229;
    --primaryCopperDark: #723723;
    --secondaryCopper: #FCB887;
    --secondaryCopperDark: #ff9549;
    --secondaryCopper2: hsl(25, 95%, 76%, .3);
    --primaryBlue: #04011C;
    --secondaryBlue: #171A36;
    --gray: #D9D9D9;
    --darkGray: #80818D;
    --lightGray: #F0F0F0;
    --extraLightGray: #f6f6f6;
    --white: white;
    --black: black;
    --lightBlack: rgba(0, 0, 0, 0.7);
    --lightBlackPrimary: #3D3D3D;
    --success: #18AE00;
    --danger: #FF3838;
    --warning: #DE8500;
    --star: #ffc107;
    --primaryBg: linear-gradient(45deg,
            rgb(255, 250, 246) 0%,
            rgb(230, 240, 255) 22%,
            rgb(250, 252, 255) 79%,
            rgb(245, 248, 255) 100%);
} */



body {
    background: var(--primaryBg);
    min-height: 100vh;
    overflow-x: hidden;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fs08 {
    font-size: 0.8rem;
}

.fs09 {
    font-size: 0.9rem;
}

.lightBlackPrimary {
    color: var(--lightBlackPrimary);
}

.faithzyLogoDiv {
    width: 55px;
    height: 55px;
}

.faithzyLogoDiv .faithzyLogo {
    width: 100%;
    height: 100%;
}

.selectDisable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.section {
    width: 93%;
    margin: auto;
}

a {
    color: var(--lightBlackPrimary);
    transition: 0.1s;
}

a:hover {
    color: var(--black);
}


.black {
    color: var(--black);
}

.black:hover {
    color: var(--black);
}

.darkGray {
    color: var(--darkGray);
    transition: 0.2s;
}

.darkGray:hover {
    color: var(--black);
}

.horizontalLine {
    width: 100%;
    height: 2px;
    background: var(--lightGray);
    border: none;
}

.verticalLine {
    width: 2px;
    height: 100%;
    background: var(--lightGray);
}

.starIcon,
.starIconFilled {
    color: var(--star);
    height: 17px;
    width: 17px;
}

.primaryBtn {
    background-color: var(--primaryBlue);
    padding: 8px 22px;
    border-radius: 8px;
    color: var(--white);
    border: 2px solid transparent;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
}

.primaryBtn .icon {
    width: 20px;
    height: 20px;
}

.primaryBtn:hover {
    background: transparent;
    border: 2px solid var(--primaryBlue);
    color: var(--black);
}

.primaryBtn:disabled,
.primaryBtn.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
}

.primaryBtn:disabled:hover,
.primaryBtn.disabled:hover {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
    border: 2px solid transparent;
}

.primaryBtn2,
.primaryBtn3 {
    background: transparent;
    padding: 8px 22px;
    border-radius: 8px;
    color: var(--primaryBlue);
    border: 2px solid var(--primaryBlue);
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.primaryBtn3 {
    border-color: var(--primaryCopper);
    color: var(--primaryCopper);
}

.primaryBtn2:hover {
    background: var(--primaryBlue);
    color: var(--white);
}

.primaryBtn3:hover {
    background: var(--primaryCopper);
    color: var(--white);
}

.primaryBtn2:disabled,
.primaryBtn3:disabled,
.primaryBtn2.disabled,
.primaryBtn3.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
    border: 2px solid transparent;
}

.primaryBtn2:disabled:hover,
.primaryBtn3:disabled:hover {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
    border: 2px solid transparent;
}

.secondaryBtn {
    background-color: var(--orangeBgLight);
    color: var(--primaryCopper);
    padding: 8px 15px;
    border-radius: 8px;
    font-weight: 500;
    transition: 0.2s;
    cursor: pointer;
    border: 2px solid transparent;
    text-align: center;
}

.secondaryBtn:hover {
    background-color: var(--orangeBg);
}

.secondaryBtn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
}

.secondaryBtn:disabled:hover {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
}

.dangerBtn {
    background-color: var(--danger);
    color: var(--white);
    padding: 8px 15px;
    border-radius: 8px;
    font-weight: 500;
    transition: 0.2s;
    cursor: pointer;
    text-align: center;
    border: 2px solid transparent;
}

.dangerBtn:hover {
    background-color: transparent;
    color: var(--danger);
    border: 2px solid var(--danger);
}

.dangerBtn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
}

.dangerBtn:disabled:hover {
    background-color: #ccc;
    cursor: not-allowed;
    color: var(--black);
    border: 2px solid transparent;
}

.primaryHeading {
    font-weight: 500;
    font-size: 32px;
}

.primaryHeading span {
    color: var(--primaryCopper);
}

.secondaryHeading {
    font-weight: 500;
    font-size: 20px;
    color: var(--black);
}

.secondaryHeading span {
    color: var(--primaryCopper);
}

.inputField {
    width: 100%;
    border: 1px solid var(--darkGray);
    border-radius: 8px;
    padding: 10px 20px;
    margin: 5px 0px;
}

.inputField:disabled {
    background: var(--lightGray);
    cursor: not-allowed;
    border: 1px solid var(--gray);
}

.dropdown {
    background: transparent;
    color: var(--black);
    outline: none;
    border: none;
    cursor: pointer;
}

.infoText {
    color: var(--darkGray);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 7px 0px;
}

.infoText .icon {
    font-size: 0.9rem;
}

.unreadBadge,
.unreadCardBadge,
.unreadCountBadge,
.unreadBadge2 {
    border-radius: 50%;
    background: var(--danger);
    color: var(--white);
    width: 10px;
    height: 10px;
    right: -25%;
    top: 30%;
    position: absolute;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.unreadCountBadge {
    background: var(--primaryCopper);
    width: 15px;
    height: 15px;
    right: -40%;
    top: -20%;
}

.unreadCardBadge {
    top: 10%;
    right: 1%;
}

.unreadBadge2 {
    top: 40%;
    right: 45%;
}

.notAuthorizedError {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    background: var(--extraLightGray);
    margin: 15px 0px;
    padding: 25px 30px;
    border-radius: 8px;
    width: 100%;
}

.notAuthorizedError .actionBtns {
    display: flex;
    gap: 15px;
}

.notAuthorizedError .actionBtns button {
    padding: 15px 25px;
}


.iconBtn {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1rem;
    padding: 9px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    color: var(--lightBlackPrimary);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    background-color: var(--orangeBgLight);
}

.iconBtn:hover {
    background-color: var(--orangeBg);
    color: var(--primaryCopper);
}




.form {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.form .inputDiv {
    width: 100%;
}

.form .inputDiv .inputInnerDiv {
    width: 100%;
}

.form .inputDiv:has(.inputInnerDiv) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.form textarea {
    resize: vertical;
    min-height: 120px;
}

.form .inputDiv .passwordFieldUpper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form .inputDiv .passwordFieldUpper .hidePasswordBtn {
    color: var(--lightBlackPrimary);
    cursor: pointer;
    font-size: 0.95rem;
    margin-right: 5px;
    transition: 0.1s;
}

.form .inputDiv .passwordFieldUpper .hidePasswordBtn:hover {
    color: var(--black);
}


label {
    font-weight: 500;
}

label span {
    color: var(--danger);
}

.uploadIcon {
    font-size: 50px;
    color: var(--darkGray);
}


.singleLineText {
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em;
    font-weight: 600;
}

.ratingsDiv {
    display: flex;
    gap: 8px;
    align-items: center;
}


/* **************** CHECKBOX **************** */

.checkboxDiv {
    display: flex;
    align-items: end;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    margin-bottom: 12px;
    user-select: none;
}

.checkboxDiv label {
    cursor: pointer;
}

.checkboxDiv label {
    cursor: pointer;
}

.checkbox {
    -webkit-appearance: none;
    appearance: inherit;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--black);
    margin-right: 8px;
    margin-bottom: 3px;
    position: relative;
    background: transparent;
    cursor: pointer;
}

.checkbox:checked {
    background: var(--black);
    content: "✓";
    color: var(--white);
}

.checkbox:disabled {
    background: var(--gray);
    border: 2px solid var(--gray);
    cursor: auto;
    content: "✓";
}

.checkbox:checked::after {
    content: '\2713';
    font-size: 12px;
    position: absolute;
    top: 0px;
    left: 3px;
    font-weight: bolder;
    color: var(--white);
}

.optionsContainer {
    position: relative;
    user-select: none;
}

.optionsContainer .optionsMenu {
    position: absolute;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 20px 15px 20px;
    width: 700%;
    /* text-align: center; */
    font-size: 0.8rem;
    top: 125%;
    right: 0;
    box-shadow: 1px 1px 10px 1px var(--gray);
    /* border: 1px solid var(--gray); */
    z-index: 100;
}

.optionsContainer .optionsMenu a {
    color: var(--darkGray);
    transition: 0.1s;
}

.optionsContainer .optionsMenu a:hover {
    color: var(--black);
}



/* **************** PAGINATION **************** */

.pagination {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.pagination .pageBtn {
    border: 2px solid var(--primaryBlue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    outline: none;
    background: transparent;
    color: var(--primaryBlue);
    cursor: pointer;
    transition: 0.1s;
    font-weight: 500;
}

.pagination .pageBtn:hover,
.pagination .pageBtn.active {
    border: 2px solid var(--primaryCopper);
    background: var(--white);
    color: var(--primaryCopper);
    font-weight: 600;
}



/* **************** LEFT AND RIGHT ARROW **************** */

.leftArrow,
.rightArrow {
    width: 40px;
    height: 40px;
    background: var(--primaryBlue);
    border: 2px solid var(--primaryBlue);
    outline: none;
    color: var(--white);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    padding: 10px;
}

.leftArrow .arrowIcon,
.rightArrow .arrowIcon {
    width: 25px;
    height: 25px;
}

.leftArrow:disabled,
.rightArrow:disabled {
    cursor: not-allowed;
}

.leftArrow:not(:disabled):hover,
.rightArrow:not(:disabled):hover {
    background: transparent;
    color: var(--primaryBlue);
}




/* **************** DROPDOWN **************** */

.dropdownPlus,
.dropdownLight {
    background: var(--primaryBlue);
    color: var(--white);
    padding: 10px 20px;
    padding-right: 40px;
    border-radius: 8px;
    cursor: pointer;
    appearance: none;
    width: fit-content;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background-image: url("../../public/assets/icons/arrowDown.png");
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 10px;
}

.dropdownLight {
    background: transparent;
    color: var(--black);
    background-image: url("../../public/assets/icons/arrowdownBlack.png");
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 10px;
    width: 100%;
}



/* **************** CART COUNTER **************** */

.cartCountBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primaryBlue);
    width: 100%;
    color: var(--white);
    border-radius: 8px;
    min-width: 100px;
}

.cartCountBtn .minus,
.cartCountBtn .plus {
    cursor: pointer;
    padding: 0px 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    user-select: none;
}

.cartCountBtn .minus {
    border-right: 1px solid var(--white);
}

.cartCountBtn .plus {
    border-left: 1px solid var(--white);
}




/* **************** POPUP BOX **************** */

.popupDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--lightBlack);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1000;
}

.popupDiv .popupContent {
    width: fit-content;
    height: fit-content;
    min-width: 50%;
    max-width: 70%;
    overflow: auto;
    max-height: 90%;
    background: var(--white);
    padding: 20px 30px;
    border-radius: 8px;
}

.popupDiv .popupContent .buttonsDiv {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
}

.popupDiv .popupContent .buttonsDiv a,
.popupDiv .popupContent .buttonsDiv button {
    width: 100%;
}

.popupDiv .popupCloseBtn {
    position: fixed;
    top: 5%;
    right: 3%;
}

.popupDiv .popupCloseBtn .icon {
    color: var(--gray);
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: 0.1s;
}

.popupDiv .popupCloseBtn .icon:hover {
    color: var(--white);
}





.popupDiv .previewImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60vh;
}

.popupDiv .previewImgDiv img {
    max-width: 100%;
    max-height: 100%;
}





/* **************** TABLE **************** */

.tableDiv {
    padding: 10px 0px 20px 0px;
}

.tableDiv .tableContent {
    background: var(--white);
    box-shadow: 1px 1px 10px 1px var(--gray);
    padding: 20px 0px;
    border-radius: 8px;
    width: 100%;
}

.tableDiv .upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 30px;
    margin-bottom: 15px;
}

.tableDiv .upper .upperRight {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tableDiv .header {
    display: flex;
    background: var(--primaryBlue);
    width: 100%;
    color: var(--white);
    text-align: center;
    padding: 10px 32px;
    font-weight: 500;
    gap: 15px;
}

.tableDiv .header p {
    width: 100%;
    text-align: center;
}

.tableDiv .header .title {
    width: 150%;
    text-align: left;
}

.tableDiv .rows {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.tableDiv .rows .row {
    display: flex;
    gap: 15px;
    width: 100%;
    padding: 5px 32px;
    padding-bottom: 10px;
}

.tableDiv .rows .row .field {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.tableDiv .rows .row .titleField {
    width: 150%;
    justify-content: left;
    align-items: center;
}

.tableDiv .rows .row .titleField .title {
    width: 98%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em;
    font-weight: 500;
}

.tableDiv .rows .row .priceField {
    font-weight: 700;
    font-size: 1.2rem;
    color: var(--primaryBlue);
}

.tableDiv .rows .row .field .icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--lightBlack);
    transition: 0.2s;
}

.tableDiv .rows .row .field .icon:hover {
    color: var(--black);
}

.tableDiv .totalRows {
    font-size: 1rem;
    color: var(--darkGray);
    margin-left: 5px;
}









/* **************** LOADER **************** */

.loadingCheckmate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
}

.loadingSimple,
.loadingSimpleMini,
.loadingSimpleMax {
    height: 78vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingSimpleMax {
    height: 84vh;
}

.loadingSimpleMini {
    height: 40vh;
}

.loadingMini,
.loadingMiniPlus {
    /* padding: 20px 0px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #3498db;
    border-top: 5px solid #2ecc71;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loadingMini .loadingSpinner {
    width: 40px;
    height: 40px;
}

.loadingMini .loadingSpinner .spinner {
    width: 30px;
    height: 30px;
}

.loadingMiniPlus .loadingSpinner {
    width: 30px;
    height: 30px;
}

.loadingMiniPlus .loadingSpinner .spinner {
    width: 20px;
    height: 20px;
    border: 4px solid #3498db;
    border-top: 4px solid #2ecc71;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}





/* **************** CONFIRMATION DIALOG **************** */

.confirmDialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.confirmDialogOverlay .confirmDialogContent {
    background: var(--white);
    border-radius: 8px;
    padding: 30px 20px;
    width: 320px;
    text-align: center;
    position: relative;
}

.confirmDialogOverlay .confirmDialogContent .confirmIcon {
    color: #dc3545;
    font-size: 3rem;
    margin-bottom: 10px;
}

.confirmDialogOverlay .confirmDialogContent .dialogButtons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}






/* **************** CROP MODEL **************** */


.cropModalOverlay {
    position: fixed;
    inset: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.cropModalOverlay .cropModalContent {
    background: var(--white);
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
}

.cropModalOverlay .cropHeader {
    font-size: 1.1rem;
    font-weight: 500;
    padding: 10px 20px;
    background-color: var(--lightGray);
    flex-shrink: 0;
}

.cropModalOverlay .cropContainer {
    position: relative;
    flex: 1;
    background-color: var(--primaryBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55vh;
    overflow: hidden;
}

.cropModalOverlay .cropActions {
    padding: 10px 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.cropModalOverlay .cancelButton,
.cropModalOverlay .cropButton {
    border: none;
    padding: 8px 18px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: 0.1s;
}

.cropModalOverlay .cancelButton {
    background-color: #6c757d;
    color: var(--white);
}

.cropModalOverlay .cancelButton:hover {
    background-color: #5a6268;
}

.cropModalOverlay .cropButton {
    background-color: var(--primaryBlue);
    color: var(--white);
}

.cropModalOverlay .cropButton:hover {
    background-color: var(--black);
}