/* ********************* ORDERS PAGE ********************* */

.ordersDiv {
    padding: 20px 0px 50px 0px;
}

.ordersDiv .upper {
    margin-bottom: 30px;
    box-shadow: 1px 1px 10px 1px var(--gray);
    padding: 20px 0px;
    border-radius: 8px;
}

.ordersDiv .upper .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ordersDiv .responsiveFilters {
    display: none;
}

.ordersDiv .upper .upperRight {
    display: flex;
    gap: 20px;
    align-items: center;
}

.ordersDiv .orders {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ordersDiv .orders .subOrders {
    display: flex;
    flex-direction: column;
    gap: 15px;
}






/* ********************* SELLER & BUYER PRODUCT ORDER CARD ********************* */

.sellerProductOrderCard,
.buyerProductOrderCard {
    box-shadow: 1px 1px 10px 1px var(--gray);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.sellerProductOrderCard .order,
.buyerProductOrderCard .order {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    position: relative;
}

.sellerProductOrderCard .actionsDiv,
.buyerProductOrderCard .actionsDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.9rem;
    /* margin: 15px 0px; */
}

.sellerProductOrderCard .actionsDiv a,
.buyerProductOrderCard .actionsDiv a,
.sellerProductOrderCard .actionsDiv div,
.buyerProductOrderCard .actionsDiv div {
    position: relative;
    color: var(--primaryCopper);
    font-weight: 600;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    width: 100%;
    padding: 10px 30px;
    background: var(--extraLightGray);
}

.sellerProductOrderCard .actionsDiv a .icon,
.buyerProductOrderCard .actionsDiv a .icon,
.sellerProductOrderCard .actionsDiv div .icon,
.buyerProductOrderCard .actionsDiv div .icon {
    width: 20px;
    height: 20px;
}

.sellerProductOrderCard .actionsDiv a:hover,
.buyerProductOrderCard .actionsDiv a:hover,
.sellerProductOrderCard .actionsDiv div:hover,
.buyerProductOrderCard .actionsDiv div:hover {
    background: var(--lightGray);
}

.sellerProductOrderCard .order .left,
.buyerProductOrderCard .order .left {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}

.sellerProductOrderCard .order .left .leftLeft,
.buyerProductOrderCard .order .left .leftLeft {
    display: flex;
    gap: 15px;
    min-width: 50%;
    max-width: 55%;
    /* background: green; */
}

.sellerProductOrderCard .order .left .leftLeft .imgDiv,
.buyerProductOrderCard .order .left .leftLeft .imgDiv {
    width: 90px;
}

.sellerProductOrderCard .order .left .leftLeft .imgDiv img,
.buyerProductOrderCard .order .left .leftLeft .imgDiv img {
    border-radius: 8px;
    width: 100%;
}

.sellerProductOrderCard .order .left .leftLeft .productInfo,
.buyerProductOrderCard .order .left .leftLeft .productInfo {
    width: 100%;
}

.sellerProductOrderCard .order .left .leftLeft .category,
.buyerProductOrderCard .order .left .leftLeft .category {
    color: var(--primaryCopper);
    font-weight: 500;
}

.sellerProductOrderCard .order .left .leftRight,
.buyerProductOrderCard .order .left .leftRight {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    gap: 60px;
    min-width: 25%;
    /* background: red; */
}

.sellerProductOrderCard .order .left .leftRight .responsivePrice,
.buyerProductOrderCard .order .left .leftRight .responsivePrice {
    display: none;
}

.sellerProductOrderCard .order .left .leftRight .column,
.buyerProductOrderCard .order .left .leftRight .column {
    text-align: center;
}

.sellerProductOrderCard .order .left .leftRight .column p,
.buyerProductOrderCard .order .left .leftRight .column p {
    font-size: 1.2rem;
    color: var(--darkGray);
}

.sellerProductOrderCard .order .left .leftRight .column a,
.sellerProductOrderCard .order .left .leftRight .column div,
.buyerProductOrderCard .order .left .leftRight .column a,
.buyerProductOrderCard .order .left .leftRight .column div {
    font-weight: 600;
    color: var(--black);
}

.sellerProductOrderCard .order .right,
.buyerProductOrderCard .order .right {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 20px 0px;
    border-left: 2px solid var(--lightGray);
}

.sellerProductOrderCard .order .right .price,
.buyerProductOrderCard .order .right .price {
    color: var(--primaryCopper);
    font-size: 1.5rem;
    font-weight: 600;
}






/* ********************* PRODUCT ORDER DETAILS PAGE ********************* */

.orderDetailsDiv {
    margin: 20px 0px 50px 0px;
}

.orderDetailsDiv .orderDetailsContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.orderDetailsDiv .orders>.upper {
    background: var(--primaryBlue);
    color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    color: var(--lightGray);
}
.orderDetailsDiv .orders>.upper .upperRight{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.orderDetailsDiv .orders>.upper .upperRight .secondaryBtn{
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 7px 10px;
}

.orderDetailsDiv .orders:has(.buyerProductOrderDetailsCard) .upper {
    border-radius: 8px;
}

.orderDetailsDiv .orders .upper .upperLeft .orderId {
    font-size: 1.3rem;
    margin-bottom: 5px;
}

.orderDetailsDiv .orders .upper .upperRight {
    font-size: 1.8rem;
    color: var(--orangeOnDarkBg);
    font-weight: 500;
}

.sellerProductOrderDetailsCard {
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid var(--gray);
    /* padding: 0px 40px; */
}

.sellerProductOrderDetailsCard .left,
.buyerProductOrderDetailsCard .left {
    width: 100% !important;
}

.sellerProductOrderDetailsCard .actionsDiv div,
.buyerProductOrderDetailsCard .actionsDiv div {
    display: flex;
    align-items: center;
    gap: 5px;
    user-select: none;
}

.buyerProductOrderDetailsCard {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
}

.buyerProductOrderDetailsCard .statusAction,
.sellerProductOrderDetailsCard .statusAction {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.buyerProductOrderDetailsCard .statusAction .btns,
.sellerProductOrderDetailsCard .statusAction .btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.orderDetailsDiv .billingInfo {
    border-radius: 8px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.orderDetailsDiv .billingInfo .buyerDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.orderDetailsDiv .billingInfo .buyerDetails .row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}



.orderDetailsDiv .orderStatusDetails {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
    padding: 25px 40px;
}

.orderDetailsDiv .orderStatusDetails .trackOrder {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow {
    position: relative;
    padding: 0px 0px 0px 45px;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow::before {
    position: absolute;
    content: "";
    top: 5px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--primaryCopperDark);
    background: var(--white);
    z-index: 10;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow::after {
    position: absolute;
    content: "";
    top: 23px;
    left: 13px;
    width: 2px;
    height: 100%;
    background: var(--primaryCopperDark);
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow.lastElem::before {
    background: var(--primaryCopperDark);
    width: 25px;
    height: 25px;
    left: 0px;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow.lastElem::after {
    top: 0;
    height: 0%;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow .trackOrderStatus {
    display: flex;
    gap: 5px;
    /* align-items: center; */
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow .trackOrderStatus p {
    font-weight: 600;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow .trackOrderStatus .reason {
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--darkGray);
    margin-top: 3px;
}

.orderDetailsDiv .orderStatusDetails .trackOrderRow .trackOrderDate {
    font-size: 0.8rem;
    color: var(--darkGray);
    margin-top: 3px;
}

.orderDetailsDiv .orderStatusDetails .inputDiv .dropdownPlus {
    width: 100%;
    margin: 10px 0px;
}

.orderDetailsDiv .orderStatusDetails .inputDiv .actions {
    display: flex;
    justify-content: space-between;
}

.orderDetailsDiv .orderStatusDetails .inputDiv .actions .btnsDiv {
    display: flex;
    gap: 15px;
}

.orderDetailsDiv .orderStatusDetails .actionsB {
    display: flex;
    justify-content: right;
    gap: 15px;
}

.orderDetailsDiv .popupDiv .disputePopupContent,
.serviceOrderDetailsDiv .popupDiv .disputePopupContent {
    max-width: 60%;
}

.orderDetailsDiv .popupDiv .disputePopupContent .infoDiv,
.serviceOrderDetailsDiv .popupDiv .disputePopupContent .infoDiv {
    font-size: 0.8rem;
    color: var(--lightBlackPrimary);
}





/* ********************* SERVICE ORDER DETAILS PAGE ********************* */

.serviceOrderDetailsDiv {
    margin: 20px 0px 50px 0px;
}

.serviceOrderDetailsDiv .serviceOrderDetailsContent {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
}

.serviceOrderDetailsDiv .serviceOrderDetailsContent button {
    font-size: 0.7rem;
}

.serviceOrderDetailsDiv .leftContainer {
    width: 62%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.serviceOrderDetailsDiv .leftContainer .leftUpper {
    width: 100%;
    border-radius: 8px;
    padding: 20px 40px;
    height: fit-content;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.serviceOrderDetailsDiv .leftHistory {
    width: 100%;
    border-radius: 8px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: fit-content;
    padding-bottom: 50px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.serviceOrderDetailsDiv .rightBox {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.serviceOrderDetailsDiv .rightBox .orderDetails,
.serviceOrderDetailsDiv .rightBox .timeRemaining {
    border-radius: 8px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: fit-content;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.serviceOrderDetailsDiv .rightBox .orderDetails .posting {
    display: flex;
    gap: 15px;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .posting .imgDiv {
    width: 100px;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .posting .imgDiv img {
    width: 100%;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .posting .singleLineText {
    height: 2.78rem;
    -webkit-line-clamp: 2;
    line-clamp: inherit;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .details,
.serviceOrderDetailsDiv .rightBox .timeRemaining .details {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .details div,
.serviceOrderDetailsDiv .rightBox .timeRemaining .details div {
    display: flex;
    justify-content: space-between;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .details div strong,
.serviceOrderDetailsDiv .rightBox .timeRemaining .details div strong {
    color: var(--lightBlackPrimary);
    font-weight: 600;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .username {
    cursor: pointer;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .actions,
.serviceOrderDetailsDiv .rightBox .timeRemaining .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.serviceOrderDetailsDiv .rightBox .orderDetails .actions button,
.serviceOrderDetailsDiv .rightBox .timeRemaining .actions button,
.serviceOrderDetailsDiv .rightBox .orderDetails .actions a,
.serviceOrderDetailsDiv .rightBox .timeRemaining .actions a {
    width: 100%;
    font-size: 0.7rem;
}

.serviceOrderDetailsDiv .rightBox .timeRemaining .countdownBox {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin: 10px;
}

.serviceOrderDetailsDiv .rightBox .timeRemaining .countdownBox div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceOrderDetailsDiv .rightBox .timeRemaining .countdownBox div strong {
    font-size: 1.5rem;
}

.serviceOrderDetailsDiv .rightBox .timeRemaining .countdownBox .pastDue {
    color: var(--danger);
}

.serviceOrderDetailsDiv .leftHistory .history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.serviceOrderDetailsDiv .leftHistory .history .activity {
    position: relative;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .horizontalLine {
    margin-top: 8px;
}

.serviceOrderDetailsDiv .leftHistory .history .activity::before {
    position: absolute;
    content: "";
    top: 5px;
    left: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primaryCopperDark);
}

.serviceOrderDetailsDiv .leftHistory .history .activity::after {
    position: absolute;
    content: "";
    top: 30px;
    left: 12px;
    width: 2px;
    height: 100%;
    background: var(--primaryCopperDark);
}

.serviceOrderDetailsDiv .leftHistory .history .activity.lastElem::after {
    top: 0;
    height: 0%;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .upper {
    display: flex;
    justify-content: space-between;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .title {
    font-weight: 500;
    padding: 4px 0px;
    max-width: 90%;
    font-size: 0.95rem;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .actionBy {
    color: var(--primaryCopper);
}

.serviceOrderDetailsDiv .leftHistory .history .activity .time {
    color: var(--darkGray);
    font-size: 0.85rem;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .showDetails {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--darkGray);
}

.serviceOrderDetailsDiv .leftHistory .history .activity .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 0.85rem;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .details .detailsBtns {
    display: flex;
    gap: 10px;
    width: fit-content;
    margin-top: 10px;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .details .reqsActivity .question {
    font-weight: 500;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .details .deliveryImages {
    display: flex;
    justify-content: left;
    gap: 10px;
}

.serviceOrderDetailsDiv .leftHistory .history .activity .details .deliveryImages img {
    width: 50px;
}

.orderDetailsDiv .orderStatusDetails .leaveReviewDiv .leaveReviewContent,
.serviceOrderDetailsDiv .leftHistory .leaveReviewDiv .leaveReviewContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.orderDetailsDiv .orderStatusDetails .leaveReviewDiv .leaveReviewContent .starsDiv,
.serviceOrderDetailsDiv .leftHistory .leaveReviewDiv .leaveReviewContent .starsDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.orderDetailsDiv .orderStatusDetails .leaveReviewDiv .leaveReviewContent .sellerReply,
.serviceOrderDetailsDiv .leftHistory .leaveReviewDiv .leaveReviewContent .sellerReply {
    margin-top: 8px;
    background: var(--lightGray);
    padding: 12px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.orderDetailsDiv .orderStatusDetails .leaveReviewDiv .leaveReviewContent .horizontalLine,
.serviceOrderDetailsDiv .leftHistory .leaveReviewDiv .leaveReviewContent .horizontalLine {
    margin-top: 10px;
}







/* ********************* REVIEWS COMPONENT ********************* */

.reviewDiv {
    padding-bottom: 20px;
}

.reviewDiv .ratings {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 40px;
}

.reviewDiv .ratings div {
    display: flex;
    gap: 8px;
}

.reviewDiv .userReviews {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.reviewDiv .userReview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reviewDiv .userReview .upper {
    display: flex;
    justify-content: space-between;
}

.reviewDiv .userReview .upper img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.reviewDiv .userReview .upper .date {
    color: var(--darkGray);
}

.reviewDiv .userReview .upper .aboutUser {
    display: flex;
    gap: 20px;
}

.reviewDiv .userReview .upper .aboutUser .userInfo {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.reviewDiv .userReview .upper .aboutUser .userInfo .starsDiv {
    display: flex;
    gap: 5px;
}

.reviewDiv .userReview .sellerResponse {
    padding: 12px 20px;
    background: var(--lightGray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.reviewDiv .userReview .sellerResponse .secondaryHeading {
    font-size: 1.1rem;
}




/********************** NOT FOUND ***********************/

.notFoundDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    height: 100vh;
    width: 100%;
    background: var(--white);
}

.notFoundDiv .notFoundImgDiv {
    width: 300px;
}

.notFoundImgDiv img {
    width: 100%;
}




/********************** TERMS AND CONDITIONS ***********************/

.termsDiv .termsContent .termsTextDiv {
    padding: 0px 55px;
    padding-bottom: 50px;
}

.termsDiv .termsContent .termsBanner {
    width: 100%;
}




/********************** CHAT ROOM ***********************/

.disputeChatDiv {
    height: 78vh;
}

.disputeChatDiv .chatRoom {
    height: 85%;
}

.commonChatDiv .chatRoom .content {
    padding: 20px 0px;
}

.commonChatDiv .chatRoom .messages {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    gap: 10px;
}

.commonChatDiv .chatRoom .messages .message {
    padding: 7px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 70%;
    border-radius: 8px;
}

.commonChatDiv .chatRoom .messages .message.sent {
    align-self: flex-end;
    background: var(--primaryCopperDark);
    color: var(--white);
    margin-right: 15px;
}

.commonChatDiv .chatRoom .messages .message.sent .messageTime {
    color: var(--lightGray);
    /* width: 100%;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 8px;
    margin-bottom: 8px; */
}

.commonChatDiv .chatRoom .messages .message.received {
    background: var(--orangeBgLight);
    margin-left: 15px;
    width: fit-content;
    /* min-width: 40%; */
}

.commonChatDiv .chatRoom .messages .message .senderName {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
}

.commonChatDiv .chatRoom .messages .message .senderName span {
    color: var(--darkGray);
    font-size: 0.7rem;
    font-weight: 300;
}


.commonChatDiv .chatRoom .messages .message.received .senderName {
    color: var(--primaryCopper);
}


.commonChatDiv .chatRoom .messages .message .documentBox {
    display: flex;
    align-items: center;
    background-color: var(--primaryBlue);
    padding: 10px 12px;
    border-radius: 8px;
    width: fit-content;
    color: var(--white);
    gap: 10px;
    cursor: pointer;
    margin-bottom: 8px;
}

.commonChatDiv .chatRoom .messages .message .documentBox p {
    color: var(--white);
    font-size: 0.7rem;
}

.commonChatDiv .chatRoom .writeMessageDiv {
    height: fit-content;
    background: var(--lightGray);
    width: 100%;
    padding: 12px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.commonChatDiv .chatRoom .writeMessageDiv .upper input {
    border: none;
    outline: none;
    background: var(--lightGray);
    width: 100%;
}

.commonChatDiv .chatRoom .writeMessageDiv .lower {
    display: flex;
    justify-content: space-between;
}

.commonChatDiv .chatRoom .writeMessageDiv .lower .left {
    display: flex;
    align-items: center;
}

.commonChatDiv .chatRoom .writeMessageDiv .lower .left .attachIcon {
    margin-right: 15px;
}

.commonChatDiv .chatRoom .writeMessageDiv .lower .left #fileInput {
    display: none;
}

.commonChatDiv .chatRoom .writeMessageDiv .filePreview {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--lightBlack);
}

.commonChatDiv .chatRoom .writeMessageDiv .filePreview p {
    margin: 0;
    font-weight: 500;
}

.commonChatDiv .chatRoom .writeMessageDiv .filePreview .removeFileIcon {
    cursor: pointer;
}

.commonChatDiv .nothingFound {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.commonChatDiv .nothingFound .icon {
    width: 100px;
    height: 100px;
    color: var(--darkGray);
}

.orderDetailsDiv .orderStatusDetails .productOrderDisputeChat {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.orderDetailsDiv .orderStatusDetails .productOrderDisputeChat .disputeChatDiv {
    height: 75vh;
}

.orderDetailsDiv .orderStatusDetails .productOrderDisputeChat .disputeChatDiv .chatRoom {
    height: 85%;
}

.serviceOrderDetailsDiv .serviceOrderDetailsContent .serviceOrderDisputeChat {
    height: 75vh;
}

.serviceOrderDetailsDiv .serviceOrderDetailsContent .serviceOrderDisputeChat .disputeChatDiv {
    height: 80%;
}











/********************** CHAT ROOM ***********************/

.notifications-container {
    position: relative;
}

.notifications-container .notifications-icon {
    cursor: pointer;
    position: relative;
}

.notifications-container .notifications-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #ff4d4f;
    color: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.notifications-container .notifications-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 320px;
    max-height: 400px;
    overflow-y: auto;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.notifications-container .notifications-header {
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f5f5f5;
}

.notifications-container .notifications-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.notifications-container .no-notifications {
    padding: 20px;
    text-align: center;
    color: #888;
}

.notifications-container .notifications-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.notifications-container .notification-item {
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s;
}

.notifications-container .notification-item:hover {
    background-color: #f5f5f5;
}

.notifications-container .notification-item.unread {
    background-color: #f0f7ff;
}

.notifications-container .notification-content p {
    margin: 0 0 4px 0;
    font-size: 14px;
}

.notifications-container .notification-time {
    font-size: 12px;
    color: #888;
}