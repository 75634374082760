/* ********************* SELLER HEADER ********************* */

.sellerHeaderDiv {
    background-color: transparent;
    /* height: 13vh; */
}


.sellerHeaderDiv .sellerHeaderContent,
.sellerHeaderDiv .sellerHeaderContent,
.sellerHeaderActions,
.sellerHeaderDiv .sellerHeaderContent,
.sellerHeaderActions,
.leftActions,
.sellerHeaderDiv .sellerHeaderContent,
.sellerHeaderActions,
.rightActions,

.sellerHeaderDiv .sellerHeaderContent nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.sellerHeaderDiv .sellerHeaderContent nav ul .ordersHeader{
    position: relative;
}

.sellerHeaderDiv .sellerHeaderContent {
    padding: 30px 0px;
    justify-content: space-between;
}

.sellerHeaderActions {
    gap: 50px;
}

.sellerHeaderActions i {
    font-size: 20px;
    cursor: pointer;
    color: var(--lightBlack);
    transition: 0.1s;
}

.sellerHeaderActions i:hover {
    color: var(--black);
}

.sellerHeaderActions .leftActions,
.sellerHeaderActions .rightActions {
    gap: 25px;
}

.sellerHeaderActions .leftActions i {
    color: var(--primaryCopper);
    transition: 0.1s;
}

.sellerHeaderActions .leftActions i:hover {
    color: var(--primaryCopperDark);
}

.sellerHeaderActions .leftActions .inboxDiv {
    position: relative;
}

.sellerHeaderActions .rightActions {
    border: 1px solid var(--primaryCopper);
    border-radius: 8px;
    padding: 12px 25px;
    background-color: transparent;
}
.sellerHeaderActions .rightActions .optionsContainer .profilePic,
.buyerHeaderActions .rightActions .optionsContainer .profilePic{
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
}



/* ********************* BECOME A SELLER PAGE ********************* */

.becomeSellerDiv {
    padding: 20px 0px 50px 0px;
}

.becomeSellerDiv .becomeSellerContent {
    border-radius: 8px;
    padding: 50px 0px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.becomeSellerDiv form {
    margin: 30px 0px;
}

.becomeSellerDiv form .inputDiv .uploadDiv {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin: 20px 0px;
}

.becomeSellerDiv form .inputDiv .uploadDiv img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.becomeSellerDiv form .inputDiv .uploadInput {
    display: none;
}





/* ********************* CREATE PRODUCT PAGE ********************* */

.createProductDiv {
    padding: 20px 0px 50px 0px;
}

.createProductDiv .createProductContent {
    background-color: var(--white);
    box-shadow: 1px 1px 10px 1px var(--gray);
    border-radius: 8px;
    padding: 50px;
}

.createProductDiv .createProductDetails {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    margin-top: 30px;
}

.createProductDiv .createProductDetails form {
    width: 100%;
}

.createProductDiv .createProductDetails .createProductGallery {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 35%;
}

.createProductDiv .createProductDetails .createProductGallery .productThumbnail {
    width: 100%;
}

.createProductDiv .createProductDetails .createProductGallery .productThumbnailContent {
    width: 350px;
    height: 350px;
    border: 1px dashed var(--darkGray);
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
}

.createProductDiv .createProductDetails .createProductGallery .productThumbnailContent .uploadLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--gray);
    border-radius: 8px;
    cursor: pointer;
}

.createProductDiv .createProductDetails .createProductGallery .productThumbnailContent .uploadLabel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.createProductDiv .createProductDetails .createProductGallery .productThumbnailContent input {
    display: none;
}

.createProductDiv .createProductDetails .createProductGallery .productGalleryUpper,
.createProductDiv .createProductDetails .createProductGallery .productThumbnailUpper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.createProductDiv .createProductDetails .createProductGallery .clearBtn {
    cursor: pointer;
}





/* ********************* SELLER BASIC DETAILS COMPONENT ********************* */

.sellerBasicDetailsDiv {
    position: relative;
    margin: 20px 0px 50px 0px;
}

.sellerBasicDetailsDiv .sellerBasicDetailsContent {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 40px 50px;
    gap: 50px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.sellerBasicDetailsDiv .profileImageDiv {
    position: absolute;
    top: -10%;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
}

.sellerBasicDetailsDiv .profileImageDiv img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}


.sellerBasicDetailsDiv .upper {
    display: flex;
    justify-content: space-around;
    height: 200px;
}

.sellerBasicDetailsDiv .upperLeft,
.sellerBasicDetailsDiv .upperRight {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 35%;
}

.sellerBasicDetailsDiv .upperLeft a {
    width: 100%;
    text-align: center;
}

.sellerBasicDetailsDiv .upperLeft .previewProfileBtn {
    margin-top: 10px;
}

.sellerBasicDetailsDiv .upperRight div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}





/* ********************* SELLER PRODUCTS ********************* */

.sellerProductsDiv {
    margin: 20px 0px 50px 0px;
}

.sellerProductsDiv .sellerProductsHeader {
    border-radius: 8px;
    padding: 20px 35px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.sellerProductsDiv .sellerProductsHeader .primaryBtn {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.sellerProductsDiv .sellerProducts {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
}

.sellerProductsDiv .sellerProducts.grid-1 {
    grid-template-columns: repeat(1, 1fr);
}

.sellerProductsDiv .sellerProducts.grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

.sellerProductsDiv .sellerProducts.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

.sellerProductsDiv .sellerProducts.grid-4 {
    grid-template-columns: repeat(4, 1fr);
}

.sellerProductsDiv .sellerProducts.grid-5 {
    grid-template-columns: repeat(5, 1fr);
}




/* **************** SELLER PRODUCT CARD **************** */

.sellerProductCardDiv {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0.1px 0.1px 10px 1px var(--gray);
    user-select: none;
}

.sellerProductCardDiv .productImgDiv {
    width: 100%;
    height: 250px;
}

.sellerProductCardDiv .productImgDiv img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.sellerProductCardDiv .productUpper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    color: var(--primaryCopper);
    font-size: 0.85rem;
}

.sellerProductCardDiv .productUpper .ellipsis {
    position: relative;
    font-size: 22px;
    color: var(--darkGray);
    margin-right: 8px;
    cursor: pointer;
    transition: 0.1s;
}

.sellerProductCardDiv .productUpper .ellipsis i:hover {
    color: var(--black);
}

.sellerProductCardDiv .productUpper .ellipsis .optionsMenu {
    position: absolute;
    background-color: var(--white);
    box-shadow: 0.1px 0.1px 10px 1px var(--gray);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    width: 500%;
    text-align: center;
    font-size: 0.8rem;
    top: 100%;
    right: 0;
}

.sellerProductCardDiv .productUpper .ellipsis .optionsMenu a {
    color: var(--darkGray);
    transition: 0.1s;
}

.sellerProductCardDiv .productUpper .ellipsis .optionsMenu a:hover {
    color: var(--black);
}

.sellerProductCardDiv .productTitle {
    font-size: 1rem;
    color: var(--black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    margin-top: 8px;
    font-weight: 500;
}


.sellerProductCardDiv .productLower {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.sellerProductCardDiv .productLower .productLowerTop {
    display: flex;
    align-items: center;
}

.sellerProductCardDiv .productLower .productLowerBottom {
    display: flex;
    gap: 15px;
    justify-content: right;
    align-items: baseline;
}

.sellerProductCardDiv .productLower .productLowerTop {
    font-size: 0.9rem;
    gap: 5px;
}

.sellerProductCardDiv .productLower .productLowerTop p {
    color: var(--lightBlack);
    font-weight: 600;
}

.sellerProductCardDiv .productLower .productLowerTop span {
    color: var(--primaryCopper);
    font-weight: 500;
}

.sellerProductCardDiv .productLower .productPrice {
    color: var(--secondaryBlue);
    font-size: 1.8rem;
    margin-right: 5px;
}

.sellerProductCardDiv .productLower .productDiscount {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
}




/* ********************* CREATE SERVICE PAGE ********************* */

.createServiceDiv {
    padding: 20px 0px 50px 0px;
}

.createServiceDiv .createServiceContent {
    padding: 50px;
    border-radius: 8px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.createServiceDiv .form {
    margin-top: 30px;
}

.createServiceDiv .servicePackagesDiv {
    display: flex;
    align-items: top;
    justify-content: space-between;
    margin: 50px 0px;
    height: 100%;
    width: 100%;
    gap: 40px;
}

.createServiceDiv .servicePackagesDiv .header {
    display: flex;
    justify-content: space-between;
}
.createServiceDiv .servicePackagesDiv label {
    font-size: 0.8rem;
}

.createServiceDiv .verticalLine {
    height: 410px;
}

.createServiceDiv .servicePackagesDiv .servicePackage .secondaryHeading {
    text-align: center;
    margin-bottom: 10px;
}

.createServiceDiv .form .reqQuestions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0px;
}

.createServiceDiv .form .reqQuestions .reqQuestion {
    display: flex;
    justify-content: space-between;
}

.createServiceDiv .form .reqQuestions .reqQuestion .icons .icon {
    cursor: pointer;
    color: var(--darkGray);
    margin-left: 15px;
}

.createServiceDiv .form .addReq {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: right;
    align-items: end;
}

.createServiceDiv .form .addReq button {
    width: fit-content;
}







/* ********************* Gallery component ********************* */

.galleryDiv {
    width: 100%;
    margin-top: 20px;
}

.galleryDiv .galleryComponentContent .galleryUpper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.galleryDiv .galleryComponentContent .galleryUpper label {
    font-weight: 600;
    font-size: 1rem;
    color: #333;
}

.galleryDiv .galleryComponentContent .imagesLimit {
    margin-left: 5px;
    font-size: 0.9rem;
}

.galleryDiv .galleryComponentContent .clearBtn {
    font-size: 0.9rem;
    color: #f03e3e;
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease;
}

.galleryDiv .galleryComponentContent .clearBtn:hover {
    background-color: #ffe3e3;
}

.galleryDiv .galleryComponentContent .galleryContent {
    width: 100%;
    border: 1px dashed var(--darkGray);
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
}

.galleryDiv .galleryComponentContent .galleryImagesWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}

.galleryDiv .galleryComponentContent .uploadedImageCard {
    position: relative;
    width: fit-content;
    height: 90px;
    border: 1px solid #eee;
    border-radius: 6px;
    overflow: hidden;
    background-color: #f9f9f9;
}

.galleryDiv .galleryComponentContent .uploadedImageCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.galleryDiv .galleryComponentContent .removeImageBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
}

.galleryDiv .galleryComponentContent .addMoreLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border: 1px dashed #ccc;
    border-radius: 6px;
    background-color: #fafafa;
    cursor: pointer;
}

.galleryDiv .galleryComponentContent .uploadIcon {
    color: #999;
    font-size: 1.5rem;
}

.galleryDiv .galleryComponentContent .inputField {
    display: none;
}






/* ********************* SELLER SERVICES ********************* */

.sellerServicesDiv {
    margin: 20px 0px 50px 0px;
}

.sellerServicesDiv .sellerServicesHeader {
    border-radius: 8px;
    padding: 20px 25px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.sellerServicesDiv .sellerServicesHeader .primaryBtn {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.sellerServicesDiv .sellerServices {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    width: 100%;
}

.sellerServicesDiv .sellerServices.grid-1 {
    grid-template-columns: repeat(1, 1fr);
}

.sellerServicesDiv .sellerServices.grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

.sellerServicesDiv .sellerServices.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

.sellerServicesDiv .sellerServices.grid-4 {
    grid-template-columns: repeat(4, 1fr);
}

.sellerServicesDiv .sellerServices.grid-5 {
    grid-template-columns: repeat(5, 1fr);
}




/* **************** SELLER SERVICE CARD **************** */

.sellerServiceCardDiv {
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0.1px 0.1px 10px 1px var(--gray);
    user-select: none;
}

.sellerServiceCardDiv .serviceImgDiv {
    width: 100%;
    height: 180px;
}

.sellerServiceCardDiv .serviceImgDiv img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.sellerServiceCardDiv .serviceUpper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    color: var(--primaryCopper);
    font-size: 0.85rem;
}

.sellerServiceCardDiv .serviceUpper .ellipsis {
    position: relative;
    font-size: 22px;
    color: var(--darkGray);
    margin-right: 8px;
    cursor: pointer;
    transition: 0.1s;
}

.sellerServiceCardDiv .serviceUpper .ellipsis i:hover {
    color: var(--black);
}

.sellerServiceCardDiv .serviceUpper .ellipsis .optionsMenu {
    position: absolute;
    background-color: var(--white);
    box-shadow: 0.1px 0.1px 10px 1px var(--gray);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    width: 500%;
    text-align: center;
    font-size: 0.8rem;
    top: 100%;
    right: 0;
}

.sellerServiceCardDiv .serviceUpper .ellipsis .optionsMenu a {
    color: var(--darkGray);
    transition: 0.1s;
}

.sellerServiceCardDiv .serviceUpper .ellipsis .optionsMenu a:hover {
    color: var(--black);
}

.sellerServiceCardDiv .serviceTitle {
    font-size: 1rem;
    color: var(--black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    margin-top: 8px;
    font-weight: 500;
}


.sellerServiceCardDiv .serviceLower {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}


.sellerServiceCardDiv .serviceLower .serviceLowerBottom {
    display: flex;
    gap: 15px;
    justify-content: right;
    align-items: baseline;
}

.sellerServiceCardDiv .serviceLower .servicePrice {
    color: var(--secondaryBlue);
    font-size: 1.8rem;
    margin-right: 5px;
}

.sellerServiceCardDiv .serviceLower .serviceDiscount {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
}




/* ********************* TRADELEAD PAGE ********************* */

.requestDetailsModelDiv .requestDetailsModelContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.requestDetailsModelDiv .requestDetailsModelContent .categorySpan {
    color: var(--primaryCopper);
}







/* ********************* UPGRADE PAGE ********************* */

.upgradeDiv {
    margin: 20px 0px 50px 0px;
}

.upgradeDiv .upgradeContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.upgradeDiv .upgradeContent .primaryHeading,
.upgradeDiv .upgradeContent .secondaryHeading {
    text-align: center;
}

.upgradeDiv .upgradeContent .timeLine {
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: center;
}

.upgradeDiv .upgradeContent .timeLine div {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-content: center;
}

.upgradeDiv .upgradeContent .plans {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.upgradeDiv .upgradeContent .plans .plan {
    width: 100%;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    width: 35%;
    border-radius: 8px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.upgradeDiv .upgradeContent .plans .plan .price {
    font-size: 2.5rem;
    font-weight: 600;
}

.upgradeDiv .upgradeContent .plans .plan .price span {
    font-size: 1rem;
    font-weight: 600;
}

.upgradeDiv .upgradeContent .plans .plan .rows {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 15px 0px;
}

.upgradeDiv .upgradeContent .plans .plan .rows .row {
    display: flex;
    gap: 10px;
    align-items: center;
}

.upgradeDiv .upgradeContent .plans .plan .rows .row .icon {
    width: 18px;
    height: 18px;
    color: var(--success);
}

.upgradeDiv .upgradeContent .plans .plan .rows .notIncluded span {
    color: var(--darkGray);
    text-decoration: line-through;
}

.upgradeDiv .upgradeContent .plans .plan .rows .notIncluded .icon {
    color: var(--danger);
}

.upgradeDiv .upgradeContent .premiumPlan {
    color: var(--white);
    background: var(--primaryBlue) !important;
}

.upgradeDiv .upgradeContent .premiumPlan .secondaryHeading {
    color: var(--white);
}

.upgradeDiv .upgradeContent .premiumPlan .primaryBtn2 {
    color: var(--white);
    border-color: var(--white);
}

.upgradeDiv .upgradeContent .premiumPlan .discountPercent,
.upgradeDiv .upgradeContent .premiumPlan .discountExpiry span {
    color: var(--orangeOnDarkBg);
    font-weight: 600;
}

.upgradeDiv .upgradeContent .plans .plan .buttonDiv {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upgradeDiv .upgradeCheckoutModelDiv .form,
.boostDiv .boostCheckoutModelDiv .form {
    width: 100%;
}

.upgradeDiv .upgradeCheckoutModelDiv .buttonsDiv,
.boostDiv .boostCheckoutModelDiv .buttonsDiv {
    margin-top: 15px;
}

.upgradeDiv .upgradeCheckoutModelDiv .row,
.boostDiv .boostCheckoutModelDiv .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.upgradeDiv .upgradeCheckoutModelDiv .paymentOptions,
.boostDiv .boostCheckoutModelDiv .paymentOptions {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
}

.upgradeDiv .upgradeCheckoutModelDiv .paymentOptions .paymentOption,
.boostDiv .boostCheckoutModelDiv .paymentOptions .paymentOption {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid var(--gray);
    padding: 40px;
    cursor: pointer;
    width: 100%;
    transition: 0.2s;
    border-radius: 8px;
}

.upgradeDiv .upgradeCheckoutModelDiv .paymentOptions .paymentOption.selected,
.boostDiv .boostCheckoutModelDiv .paymentOptions .paymentOption.selected {
    background: var(--primaryBlue);
    color: var(--white);
}

.upgradeDiv .upgradeCheckoutModelDiv .paymentOptions .paymentOption:not(.selected):hover,
.boostDiv .boostCheckoutModelDiv .paymentOptions .paymentOption:not(.selected):hover {
    background: var(--lightGray);
}

.upgradeDiv .upgradeCheckoutModelDiv .paymentOptions .paymentOption .icon,
.boostDiv .boostCheckoutModelDiv .paymentOptions .paymentOption .icon {
    width: 40px;
    height: 40px;
    color: var(--secondaryBlue);
}

.upgradeDiv .upgradeCheckoutModelDiv .paymentOptions .paymentOption.selected .icon,
.boostDiv .boostCheckoutModelDiv .paymentOptions .paymentOption.selected .icon {
    color: var(--white);
}






/* ********************* EARNINGS PAGE ********************* */

.earningsDiv {
    margin: 20px 0px 50px 0px;
}

.earningsDiv .paymentHistoryTableDiv .filterDiv {
    display: flex;
    gap: 20px;
    align-items: center;
}

.earningsDiv .paymentHistoryTableDiv .filterDiv .customDateRange label {
    margin-left: 15px;
}

.earningsDiv .paymentHistoryTableDiv .filterDiv .customDateRange input {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid var(--gray);
    outline: none;
    margin-left: 8px;
}

.earningsDiv .sellerPaymentMethodsDiv {
    padding: 20px 30px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.earningsDiv .sellerPaymentMethodsDiv .paymentMethods {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--lightGray);
    padding: 8px 15px;
    border-radius: 8px;
    margin: 10px 0px;
}

.earningsDiv .sellerPaymentMethodsDiv .paymentMethods .method {
    display: flex;
    align-items: center;
    gap: 10px;
}

.earningsDiv .sellerPaymentMethodsDiv .paymentMethods .buttonsDiv {
    display: flex;
    align-items: center;
    gap: 15px;
}


.earningsDiv .earningsOverview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.earningsDiv .earningsOverview .overviewBox {
    border-radius: 8px;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.earningsDiv .earningsOverview .overviewBox .secondaryHeading {
    font-size: 1rem;
}

.earningsDiv .earningsOverview .overviewBox .value {
    font-size: 1.5rem;
    font-weight: 600;
}

.earningsDiv .tableDiv {
    padding: 0;
    margin: 20px 0px;
}

.earningsDiv .tableDiv .section {
    width: 100%;
}

.earningsDiv .tableDiv .upperRight {
    display: flex;
    align-items: center;
    gap: 10px;
}

.earningsDiv .popupDiv .popupContent {
    width: 40% !important;
}

.earningsDiv .tableDiv .tableContent .header .title {
    width: 200%;
}

.earningsDiv .tableDiv .tableContent .rows .titleField {
    width: 200%;
}

.earningsDiv .tableDiv .tableContent .rows .titleField .title {
    width: 100%;
}







/* ********************* BOOST PAGE ********************* */

.boostDiv {
    margin: 20px 0px 50px 0px;
}

.boostDiv .boostContent {
    display: flex;
    gap: 30px;
}

.boostDiv .boostItems {
    padding: 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.boostDiv .boostItems .itemsDiv .secondaryHeading {
    margin-bottom: 13px;
}

.boostDiv .boostItems .itemsDiv .items {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.boostDiv .boostItems .itemsDiv .items .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 15px 23px;
    box-shadow: 3px 3px 10px var(--gray);
    border-radius: 8px;
}

.boostDiv .boostItems .itemsDiv .items .item .aboutItem,
.boostDiv .boostItems .itemsDiv .items .item .itemActions {
    display: flex;
    gap: 15px;
    align-items: center;
}

.boostDiv .boostItems .itemsDiv .items .item .timeRemaining {
    min-width: 250px;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
    font-weight: 500;
    font-size: 18px;
}

.boostDiv .boostItems .itemsDiv .items .item .timeRemaining .hours {
    color: var(--danger);
}

.boostDiv .boostItems .itemsDiv .items .item .itemActions .secondaryBtn {
    min-width: 100px;
}

.boostDiv .boostItems .itemsDiv .items .item .itemActions .secondaryBtn.added {
    color: var(--danger);
}

.boostDiv .boostItems .itemsDiv .items .item .aboutItem {
    width: 100%;
}

.boostDiv .boostItems .itemsDiv .items .item .aboutItem img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.boostDiv .boostItems .itemsDiv .boostServices .item .aboutItem img {
    height: 40px;
}

.boostDiv .boostItems .itemsDiv .items .item .aboutItem .singleLineText {
    font-weight: 400;
}

.boostDiv .boostCart {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
    border-radius: 8px;
    min-width: 25%;
    height: fit-content;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.boostDiv .boostCart .checkoutItemsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.boostDiv .boostCart .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.boostDiv .boostCart .row .price {
    font-weight: 500;
}

.boostDiv .boostCart .row .totalPrice {
    font-weight: 600;
    color: var(--primaryCopper);
}